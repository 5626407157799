/** @Deprecated, use fEvents.attachEvent
 * This will override the eventhandler and adds a container property, the container is the target
 * @param on
 * @param target
 * @param handler
 * @param root - this is the component from which the vent is spawned
 */
export const attachEvent = (target, on, handler, root: Document | ShadowRoot | Element = document, useCapture = false) => (root || document).addEventListener(on, evt => {
    const container = (evt.target as Element).closest(target) || (evt.target as Element).matches(target);
    if (container) {
        const event = Object.assign(evt, { container });
        handler(event);
    }
    return false;
}, useCapture);

export const docReady = fn => {
    // see if DOM is already available
    if (document.readyState === 'complete') {
        // call on next available tick
        setTimeout(fn);
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
};

// @ts-ignore
export function requestHandler({ url,
    payload = null,
    method = 'GET',
    secure = false,
    isJson= true,
    xhr = new XMLHttpRequest(),
    disableNotification=false,
    cacheInMinutes=0 } ): Promise<any> {

    return new Promise((resolve, reject) => {
        xhr.onreadystatechange = function () {
            if (xhr.readyState !== 4) return;
            if (xhr.status >= 200 && xhr.status < 400) {
                resolve({ data: xhr.responseText, statusCode: xhr.status });
            } else {
                const { errors, message, code } = JSON.parse(xhr.responseText);

                let errorMessage = message || 'Something went wrong.';
                if (errors && errors.length) {
                    errorMessage = errors.map(err => `${err.field.toUpperCase()}: ${err.messages.join(',')}`).join('\n');
                }
                if (!disableNotification) {
                    showMessage({ message: errorMessage });
                }
                reject({ code, message: errorMessage });
            }
        };

        xhr.open(method, url, true);
        xhr.setRequestHeader('Cache-Control', 'public, max-age=' + cacheInMinutes); // Cache for 1 minutes
        if (isJson) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.withCredentials = secure;
        xhr.send(payload);
    });
}

export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const popovers = [];
export const showMessage = err => {
    const popover = makeNativeToast();
    const errorSpan = document.createElement('span');
    const closeButton = document.createElement('button');
    errorSpan.innerText = err?.message || err;
    closeButton.classList.add('toast-close-btn');
    closeButton.innerHTML = '<span id="toast-close-icon" class="material-icons">close</span>';
    errorSpan.classList.add('toast-content');
    [errorSpan, closeButton].forEach(el => popover.appendChild(el));

    // Remove the toast again after 4 seconds
    let timeoutId = setTimeout(() => {
        closePopover(popover);
    }, 4000);

    document.body.appendChild(popover);
    //@ts-ignore
    popover.showPopover();
    closeButton.addEventListener('click', () => {
        closePopover(popover);
    });

    // When a new toast appears, run the moveToastsDown() function
    moveToastsDown();

    popover.addEventListener('mouseover', () => {
        // When the user hovers over the toast, pause the timeout and resume when the mouse leaves
        clearTimeout(timeoutId);
    });

    popover.addEventListener('mouseout', () => {
        timeoutId = setTimeout(() => {
            closePopover(popover);
        }, 4000);
    });
};

const closePopover = popover => {
    popover.hidePopover();
    removePopover(popover.getAttribute('popover-index'));
    document.body.removeChild(popover);
    reArrangeToast();
};

const moveToastsDown = () => {
    popovers.forEach(toast => {
        if (toast.classList.contains('newest')) {
            toast.classList.remove('newest');
            toast.style.top = '5px';
        } else {
            // Move up all the other toasts by 75px to make way for the new one
            const prevValue = toast.style.top.replace('px', '');
            const newValue = parseInt(prevValue) + toast.offsetHeight + 5;
            toast.style.top = `${newValue}px`;
        }
    });
};

const reArrangeToast = () => {
    let offset = 5; // Starting top position for the first toast
    popovers.forEach(toast => {
        toast.style.top = `${offset}px`;
        offset += toast.offsetHeight + 5; // Increase by the height of one toast plus some margin
    });
};

// Function to remove a popover from the state
const removePopover = popoverIndex => {
    const index = popovers.findIndex(popover => popover.getAttribute('popover-index') === popoverIndex);
    if (index !== -1) {
        popovers.splice(index, 1);
    }

    popovers.forEach((popover, i) => {
        popover.setAttribute('popover-index', JSON.stringify(i + 1));
    });
};

const makeNativeToast = () =>{
    const popover = document.createElement('div');
    popovers.push(popover);
    popover.setAttribute('popover-index', JSON.stringify(popovers.length));
    popover.classList.add('fl-popover');
    popover.classList.add('newest');
    popover.setAttribute('popover', '');
    popover.setAttribute('popover', 'manual');

    return popover;
};

export const getSelectedMemberIdFromUrl = () => {
    const linkElements = window.location.pathname.split('/').filter(p => !!p);
    return linkElements.length > 2 ? linkElements[linkElements.length - 1] : '';
};

export const getFileTypeFromMediaType = mediaType => {
    if (!mediaType) return 'no-file';
    const filetype = mediaType?.indexOf('image') > -1 ? 'img' :
        mediaType?.indexOf('pdf') > -1 ? 'pdf' :
            (mediaType?.indexOf('word') > -1) ? 'doc' :
                (mediaType?.indexOf('excel') > -1 || mediaType?.indexOf('.sheet') > -1) ? 'xls' :
                    (mediaType?.indexOf('powerpoint') > -1 || mediaType?.indexOf('presentation') > -1) ? 'ppt' : 'other';

    return filetype;
};

export const getFileFormattedSize = fileSize => {
    const unit = fileSize < 1000 ? 'b' : fileSize < 1000000 ? 'KB' : 'MB';
    let size = 0;
    if (unit === 'MB') {
        size = Math.ceil(fileSize/(1024*1024));
    } else if (unit === 'KB') {
        size = Math.ceil(fileSize/1024);
    } else {
        size = fileSize;
    }

    return { unit, size: Math.ceil(size) };
};

export const formatDateToInput = date => {
    return date.getFullYear() + '-'
        + ('0' + (date.getMonth()+1)).slice(-2) + '-'
        + ('0' + date.getDate()).slice(-2);
};

export const languageCodesDescription = {
    en: 'English',
    ar: 'Arabic',
    zh: 'Chinese',
    cs: 'Czech',
    da: 'Danish',
    in: 'Indonesian',
    ms: 'Malaysian',
    nl: 'Dutch',
    fr: 'French',
    fi: 'Finnish',
    de: 'German',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    ro: 'Romanian',
    ru: 'Russian',
    es: 'Spanish',
    sv: 'Swedish',
    th: 'Thai',
    tl: 'Filipino',
    tr: 'Turkish'
};

// Added an implementation of ObjectId to avoid dragging the mongo library to frontend
export const ObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));
